
*, *::before, *::after {
  box-sizing: border-box;
}

html {
  font-size: 75%;
  background-color: #F5F6FA;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100%;
}

body {
  margin: 0;
  min-height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --amplify-primary-color: #0018F2;
  --amplify-primary-tint: #2A00A2;
  --amplify-primary-shade: #0018F2;
}

/* Recharts */
.recharts-legend-item-text {
  font-family: sans-serif;
  text-transform: capitalize;
}

.recharts-label-list text {
  font-family: sans-serif;
}

